import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Youth Conversations in Bhutan, Pakistan, Timor Leste`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` United Nations Development Programme (UNDP) conducted the "largest digital engagement to date in the Global South" with youth`}<br parentName="p"></br>{`
`}<strong parentName="p">{`When:`}</strong>{` 2020-21`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Where:`}</strong>{` Bhutan, Pakistan, Timor Leste`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Size:`}</strong>{` 30,000+  `}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` To hear from youth on their priorities for climate action leading up to COP26 in Fall 2021, UNDP Bangkok Regional Hub reached out to country offices in Bhutan, Pakistan, and Timor Leste to run a large-scale engagement. For outreach, UNDP used a technology partner to send over 10M SMS messages to phones of youth 15-19 years of age, and provided support from the Cognition Company. The message read: “raise your voice and let us know your thoughts: youthconversations.org” and was signed by the United Nations Development Programme. An overarching goal was to model how governments and legislatures could better consult their citizens on policy issues.`}</p>
    <p>{`The UNDP Bangkok Regional Hub began this initiative with country partners Bhutan, Pakistan, and Timor Leste. The digital conversation opened with a question: "What challenges need to be tackled by the government to make Bhutan, Pakistan, or Timor-Leste a better place to live in, both during COVID and in post-COVID times?" The first round was during early COVID in 2020, the second round in late 21 - early 2022 focused on climate change action. Country teams focused on bridging the digital divide, specifically the rural/urban divide, with the successful result of reaching over 30k youth. Some campaigns were more colorful than others -- in Timor Leste, the project was combined with an existing UNDP youth project and with the efforts at strengthening the national legislature and the conduct of elections, with the result that this campaign included members of Parliament riding in the back of electric tuktuks having conversations with youth leaders, who were engaging with Polis via tablet. The Timor Leste group also held a Polis worksession oceanside after a beach cleanup. This large-scale digital engagement set a public engagement precedent for governments in South and South East Asia.`}</p>
    <h4>{`Links:`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Project landing page: `}<a parentName="p" {...{
            "href": "https://www.youthconversations.org/"
          }}>{`https://www.youthconversations.org/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`United Nations Development Programme UNDP report (see page 53 / PDF page 29) `}<a parentName="p" {...{
            "href": "https://www.undp.org/sites/g/files/zskgke326/files/migration/asia_pacific_rbap/UNDP-Results-Report-2020-2021_v14_Feb16.pdf"
          }}>{`https://www.undp.org/sites/g/files/zskgke326/files/migration/asia_pacific_rbap/UNDP-Results-Report-2020-2021_v14_Feb16.pdf`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inter-Parliamentary Union (IPU) (see page 63 / PDF page 65) `}<a parentName="p" {...{
            "href": "https://www.ipu.org/file/14340/download"
          }}>{`https://www.ipu.org/file/14340/download`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cognition Company (UNDP's service delivery partner): `}<a parentName="p" {...{
            "href": "https://cogco.co/"
          }}>{`https://cogco.co/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Translations created for Timor Leste:
`}<a parentName="p" {...{
            "href": "https://github.com/compdemocracy/polis/blob/dev/client-participation/js/strings/tdt.js"
          }}>{`https://github.com/compdemocracy/polis/blob/dev/client-participation/js/strings/tdt.js`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Report links:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Bhutan: `}<a parentName="li" {...{
              "href": "https://pol.is/report/r3dacfx5uhpyawrcvwmcr"
            }}>{`https://pol.is/report/r3dacfx5uhpyawrcvwmcr`}</a></li>
          <li parentName="ul">{`Timor Leste: `}<a parentName="li" {...{
              "href": "https://pol.is/report/r2jzd3tjbzjppnnmx3x4m"
            }}>{`https://pol.is/report/r2jzd3tjbzjppnnmx3x4m`}</a></li>
          <li parentName="ul">{`Pakistan: `}<a parentName="li" {...{
              "href": "https://pol.is/report/r9k7us4bnttfmfr9czfa5"
            }}>{`https://pol.is/report/r9k7us4bnttfmfr9czfa5`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      